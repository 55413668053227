// @ts-nocheck
import { Box, Center, Flex, Grid, Image } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import welaLogo from "../../images/wela-logo.svg"
import { Trans } from "gatsby-plugin-react-i18next"
import { mixPanel } from "../../mixpanel/mixpanel"

const Footer = () => {
  const getYear = () => {
    const date = new Date()
    return `Copyright © ${date.getFullYear()} Wela School System. All rights reserved.`
  }

  const handleTrackClick = value => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", value)
      }
    }
    mixPanel.track(value)
  }

  return (
    <>
      <Box
        px={{ xl: "7rem", lg: "3rem", base: "1rem" }}
        pt="20"
        pb="6"
        bgColor={"#F3F5FC"}
      >
        <Box pb="12" mx={{ xl: "10", lg: "2rem", base: "1rem" }}>
          <Center>
            <Grid
              templateColumns={{
                "2xl": "repeat(6, 1fr)",
                xl: "repeat(4, 1fr)",
                base: "repeat(2, 1fr)",
              }}
              gap={2}
            >
              <Box gridColumnStart={{ "2xl": 2, base: 1 }}>
                <Box fontSize={40} fontWeight={800}>
                  <Image
                    src={welaLogo}
                    w={{ xl: "85%", lg: "75%", base: "70%" }}
                    alt="wela"
                    mb="0"
                  />
                </Box>
                <Box my="6" fontSize={14} fontWeight={500}>
                  Ideaspace 172 Salcedo, Legazpi Village, Makati City,
                  Philippines
                </Box>
                <Box mb="6" fontSize={14} fontWeight={500}>
                  2/F Lifestyle District, Corrales Ext, Cagayan de Oro, 9000
                  Misamis Oriental, Philippines
                </Box>
                <Box fontSize={14} fontWeight={500}>
                  (+63) 917 1304 401
                </Box>
                <Box fontSize={14} fontWeight={500}>
                  (+63) 917 1701 977
                </Box>
                <Box fontSize={14} fontWeight={500}>
                  hello@wela.online
                </Box>
              </Box>
              <Box ml={{ xl: "14" }}>
                <Box my="5" fontWeight={700} fontSize={20}>
                  <Trans>Meet Wela</Trans>
                </Box>

                <Link
                  to="/AboutUs"
                  onClick={() => handleTrackClick("About Us Tab")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    _hover={{ color: "#8326EA" }}
                  >
                    <Trans>About Us</Trans>
                  </Box>
                </Link>
                <Link
                  to="/features"
                  onClick={() => handleTrackClick("Features Tab")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    _hover={{ color: "#8326EA" }}
                  >
                    <Trans>Features</Trans>
                  </Box>
                </Link>
              </Box>
              <Box ml={{ xl: "10" }}>
                <Box my="5" fontWeight={700} fontSize={20}>
                  <Trans>Connect</Trans>
                </Box>
                <Link
                  to="https://www.facebook.com/welaschoolsystems"
                  target={"_blank"}
                  onClick={() => handleTrackClick("Facebook Link Clicked")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    _hover={{ color: "#8326EA" }}
                  >
                    Facebook
                  </Box>
                </Link>
                <Link
                  to="https://www.instagram.com/welasystems/"
                  target={"_blank"}
                  onClick={() => handleTrackClick("Instragram Link Clicked")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    _hover={{ color: "#8326EA" }}
                  >
                    Instragram
                  </Box>
                </Link>
                <Link
                  to="https://twitter.com/WelaSystems"
                  target={"_blank"}
                  onClick={() => handleTrackClick("Twitter Link Clicked")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    _hover={{ color: "#8326EA" }}
                  >
                    Twitter
                  </Box>
                </Link>
              </Box>
              <Box ml={{ xl: "6" }}>
                <Box my="5" fontWeight={700} fontSize={20}>
                  <Trans>Others</Trans>
                </Box>
                <Link
                  to="/GetADemo"
                  onClick={() => handleTrackClick("Get Demo Tab")}
                >
                  <Box
                    py="1"
                    fontSize={14}
                    fontWeight={500}
                    cursor="pointer"
                    _hover={{ color: "#8326EA" }}
                  >
                    <Trans>Get a demo</Trans>
                  </Box>
                </Link>
              </Box>
            </Grid>
          </Center>
        </Box>
        <Box>
          <Flex justifyContent={"center"}>
            <Box fontSize={14} fontWeight={600} mt="2">
              {getYear()}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default Footer
